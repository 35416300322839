<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      :title="__('Payment Gateways')"
      style="padding: 20px 30px; width: calc(100% - 335px)"
    />
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!can('system.payment-gateways.write')"
      >{{ __("Add Payment Gateway") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="paymentGatewaysTable"
            v-loading="loading"
            :data="paymentGateways"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            highlight-current-row
            class="list-table"
            v-show="can('system.payment-gateways.read')"
          >
            <el-table-column :label="__('ID')" prop="payment_gateway_id" />
            <el-table-column :label="__('Payment gateway')">
              <template slot-scope="scope">
                <span>{{ scope.row.payment_gateway_name }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "PaymentGatewaysList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["payment_gateway_name", "payment_gateway_id"]
    };
  },

  computed: {
    ...mapState("paymentgateways", {
      paymentGateways: state => state.paymentGateways,
      loading: state => state.loading
    })
  },

  methods: {
    ...mapActions("paymentgateways", {
      contentAPI: "getPaymentGateways",
      deleteContentMethod: "deletePaymentGateway",
      undoDeleteContent: "undoDeletePaymentGateway"
    }),

    handleClear() {
      this.$refs.paymentGatewaysTable &&
        this.$refs.paymentGatewaysTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      const index = this.paymentGateways.findIndex(
        paymentGateway =>
          paymentGateway.payment_gateway_id === row.payment_gateway_id
      );
      this.handleSelect(index, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      if (this.can("system.payment-gateways.write")) {
        const index = this.paymentGateways.findIndex(
          paymentGateway =>
            paymentGateway.payment_gateway_id === row.payment_gateway_id
        );
        this.handleEdit(index, row);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
